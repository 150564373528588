declare let TCPlayer: any;

const vTCPlayer = function (dom: string, fileId: string) {
    return TCPlayer(dom, { 
        fileID: fileId, 
        appID: "1256414354",
        autoplay: true 
    });
}

declare let WX: any;

const WXF = function (wx: any) {
    return wx
}

export default vTCPlayer