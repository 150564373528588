
import {
  defineComponent,
  ref,
  inject,
  nextTick,
  watch,
  onUnmounted,
} from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Loading from "@/components/UI/Loading";
import Swiper from "swiper";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
// asyncLoadJs(
//   "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
//   "about"
// ).then(() => {
//   asyncLoadJs(
//     "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
//     "about"
//   );
// });
import vTCPlayer from "@/hooks/useTcplayer";
import Modal from "@/components/UI/Modal";
export default defineComponent({
  name: "Corporation",
  components: {
    Loading,
  },
  setup() {
    const data = ref(null);
    asyncLoadJs(
      "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
      "about"
    ).then(() => {
      asyncLoadJs(
        "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
        "about"
      ).then((res) => {
        vTCPlayer("player-container-id", "387702293727945337");
      });
    });
    const userInfo = inject("userInfo") as any;
    function contractUs() {
      // 用户登录，自动提交用户的账号默认的手机号
      if (userInfo?.mobile) {
        Toast({
          type: "success",
          title: "请求已提交，电话拨打中，请稍后...",
        });
        axios.post("/M/Server/GetRandKefuName").then((res) => {
          const kefuname = res.data.msg;
          const urlstr =
            "http://211.151.35.102/app?Action=Dialout&ActionID=1234567890&Account=N000000014105&PBX=1.1.1.108&Exten=" +
            userInfo.mobile +
            "&FromExten=" +
            kefuname;

          window.open(urlstr);
        });
      } else {
        Modal({
          title: "免费电话",
          content: `  <div>请告知您的电话号码，我们将立即回电</div>
                <span>通话对您免费，请放心接听</span>`,
          dangerouslyUseHTMLString: true,
          placeholder: "请输入电话号码",
          input: true,
          inputPattern: /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/,
          inputErrorMessage: "请输入正确的手机号或带区号的固定电话",
          onConfirm: (tel) => {
            console.log(tel);

            Toast({
              type: "success",
              title: "请求已提交，电话拨打中，请稍后...",
            });

            axios.post("/M/Server/GetRandKefuName").then((res) => {
              const kefuname = res.data.msg;
              const urlstr =
                "http://211.151.35.103/app?Action=Dialout&ActionID=1234567890&Account=N000000014243&PBX=1.1.2.107&Exten=" +
                tel +
                "&FromExten=" +
                kefuname;

              window.open(urlstr);
            });
          },
        });
      }
    }
    let Videoplayer: any = null;
    let VideoplayerContent: any = null;
    const videoFileid = ref(true);
    axios
      .get("/M/Home/About")
      .then((res) => {
        if (res.data.success) {
          const kefulist = [];
          const length = Math.ceil(res.data.obj.kefulist.length / 6);
          for (let i = 0; i < length; i++) {
            kefulist.push(res.data.obj.kefulist.slice(i * 6, i * 6 + 6));
          }
          data.value = { ...res.data.obj, kefulist };
          nextTick(() => {
            Videoplayer = vTCPlayer(
              "player-container-id",
              "387702293727945337"
            );
            VideoplayerContent = vTCPlayer(
              "playerContentVideo",
              "387702293727945337"
            );
          });
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });

    watch(data, () => {
      nextTick(() => {
        const swiper = new Swiper(".swiper-container", {
          initialSlide: 0,
          autoplay: true,
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
          pagination: {
            el: ".swiper-pagination",
          },
        });
      });
    });

    onUnmounted(() => {
      Videoplayer.dispose();
      // VideoplayerContent.dispose();
      videoFileid.value = false;
    });

    const loadOption = {
      text: "加载中...",
      color: "#b9135a",
      textColor: "#b9135a",
    };
    return {
      data,
      loadOption,
      contractUs,
      videoFileid,
    };
  },
});
