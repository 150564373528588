<template>
  <main v-if="data">
    <div class="banner">
      <div class="swiper-slide">
        <router-link
          :to="item.slideMLink"
          v-for="(item, index) in data.listimg"
          :key="index"
          :style="{ backgroundImage: `url(${item.slideImg})` }"
          :title="item.slideAlt"
          class="swiper-content"
        >
        </router-link>
      </div>
    </div>

    <div class="main-title">
      <div class="main-title-border">
        联系方式
      </div>
    </div>

    <div class="position-info">
      <p>业务：010-58103629，400-8830-979</p>
      <p>E-mail：info@gbw114.com</p>
      <p>投诉：crmch66@163.com</p>
      <p>地址：北京市朝阳区南磨房路37号1701-1703室</p>
      <p>邮编：100022</p>
      <div class="contract-wapper">
        <a
          href="https://tb.53kf.com/code/client/36b28c56331fefd0e0541943db1facc92/1"
          class="inline"
        >
          <img src="@/assets/images/mine/kefu.png" alt="" />
          在线客服
        </a>
        <a
          class="free-tel"
          @click="contractUs"
          href="javascript:;"
          rel="nofollow"
        >
          <img src="@/assets/images/mine/phone.png" style="width:0.6rem;height:0.6rem" alt="" />
          免费电话
        </a>
      </div>
    </div>

    <div class="free-tel-model"></div>
    <!-- 免费电话 -->
    <div class="free-tel-wapper">
      <div class="free-tel-title">免费电话</div>
      <div class="free-tel-tip">
        <div>请告知您的电话号码，我们将立即回电</div>
        <span>通话对您免费，请放心接听</span>
      </div>
      <input
        type="tel"
        id="callMeTel"
        name="callMeTel"
        class="About_tellPhone_input"
        placeholder="请输入电话号码"
        value=""
      />
      <div class="free-tel-btn">
        <span class="free-tel-cancel">取消</span>
        <span class="free-tel-confirm">立即回电</span>
      </div>
    </div>
    <!--                          业务部电话                                 -->
    <div class="block">
      <div class="block-title border-bottom-1px">
        <div class="main-title-border">
          优秀业务代表
        </div>
      </div>
      <div class="custom-service">
        <div class="swiper-container" id="swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in data.kefulist"
              :key="index"
            >
              <ul class="custom-service-ul">
                <li v-for="(itm, i) in item" :key="i">
                  <router-link
                    :to="{ name: 'Personal', query: { uid: itm.serverUserId } }"
                  >
                    <img class="avatar" :src="itm.serverImg" alt="" />
                    <div class="service-info">
                      <span>
                        <a href="javascript:;" >
                          {{ itm.serverName }}
                        </a>
                      </span>
                      <span>手机：{{ itm.serverMobile }}</span>
                      <span>QQ：{{ itm.serverQq }}</span>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>

          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <!-- 账户信息    -->
    <div class="block">
      <div class="block-title border-bottom-1px">
        <div class="main-title-border">
          账户信息
        </div>
      </div>
      <div class="account-info">
        <ul>
          <li>
            <p style="color:#b9135a;font-weight:bold;font-size:16px">增值税专用发票账户</p>
            <p>北京盈泽纳新化工技术研究院</p>
            <p>账号: 0200 0100 0920 0382 457</p>
            <p>开户行: 中国工商银行北京北太平庄支行</p>
          </li>
          <li>
            <p style="color:#b9135a;font-weight:bold;font-size:16px">普通发票帐户</p>
            <p>北京普天同创生物科技有限公司</p>
            <p>账号: 0200 0100 0920 0436 048</p>
            <p>开户行: 中国工商银行北京北太平庄支行</p>
          </li>
          <li style="border-bottom:0;padding-bottom:0">
            <img style="width:45%;margin-left:27%" src="@/assets/images/zhipaycode.jpg">
          </li>
        </ul>
      </div>
    </div>


  <!-- 公司简介  视频修改-->
    <div class="block">
      <div class="block-title border-bottom-1px">
       <div class="main-title-border">
          公司简介
        </div>
      </div>
      <div class="video-wapper">
        <video
          id="player-container-id"
          v-if="videoFileid"
          preload="auto"
          style="width: 100%; height: 100%"
          playsinline
          webkit-playinline
          x5-playinline
          autoplay="autoplay"
        ></video>
      </div>
      <div class="tip-container">
        <div class="main-title-border">
          <p style="color:#b9135a;font-size:16px;font-weight:550;line-height:16px">北京普天同创生物科技有限公司</p>
        </div>
          <p style="margin-top: 0.267rem;text-indent: 2em;">
           北京普天同创生物科技有限公司，成立于2011年，是一家专注于标准物质和计量仪器领域的科研企业。成立至今，
        为深耕计量领域服务计量标准，普天同创始终坚持“科学严谨、用心服务”的经营理念，通过十多年的积累与沉淀，
        逐步实现集研发、生产、销售、服务为一体的专业供应链。公司拥有国内外数十万种标准物质、标准品等计量检测专用化学制剂，
        自主研发具有国内先进水平的精密计量器具，荣获多项专利证书。完备的标准物质保藏和管理系统，多系列产品定制服务，千万级仓储，
        快速响应式官网平台，闪电式发货，为国家检测机构、科研高校及企业提供精准化、权威化、专业化的优质服务，在业内得到广大用户的
            一致好评。服务科研，助力检测。</p>
            <p style="text-indent: 2em;">普天同创期待与您一同为中国计量标准检测事业的发展与进步共同奋斗。携手普天，同创辉煌！</p>

         <div style="font-size:16px;float:right">
            <router-link :to="{ name: 'Corporation' }"
            >[查看更多]</router-link>
          </div>
        </div>
    </div>


  </main>
  <loading :options="loadOption" v-else></loading>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  inject,
  nextTick,
  watch,
  onUnmounted,
} from "vue";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import Loading from "@/components/UI/Loading";
import Swiper from "swiper";
import asyncLoadJs from "@/hooks/useAsyncLoadJs";
// asyncLoadJs(
//   "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
//   "about"
// ).then(() => {
//   asyncLoadJs(
//     "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
//     "about"
//   );
// });
import vTCPlayer from "@/hooks/useTcplayer";
import Modal from "@/components/UI/Modal";
export default defineComponent({
  name: "Corporation",
  components: {
    Loading,
  },
  setup() {
    const data = ref(null);
    asyncLoadJs(
      "//imgcache.qq.com/open/qcloud/video/tcplayer/lib/hls.min.0.8.8.js",
      "about"
    ).then(() => {
      asyncLoadJs(
        "//imgcache.qq.com/open/qcloud/video/tcplayer/tcplayer.min.js",
        "about"
      ).then((res) => {
        vTCPlayer("player-container-id", "387702293727945337");
      });
    });
    const userInfo = inject("userInfo") as any;
    function contractUs() {
      // 用户登录，自动提交用户的账号默认的手机号
      if (userInfo?.mobile) {
        Toast({
          type: "success",
          title: "请求已提交，电话拨打中，请稍后...",
        });
        axios.post("/M/Server/GetRandKefuName").then((res) => {
          const kefuname = res.data.msg;
          const urlstr =
            "http://211.151.35.102/app?Action=Dialout&ActionID=1234567890&Account=N000000014105&PBX=1.1.1.108&Exten=" +
            userInfo.mobile +
            "&FromExten=" +
            kefuname;

          window.open(urlstr);
        });
      } else {
        Modal({
          title: "免费电话",
          content: `  <div>请告知您的电话号码，我们将立即回电</div>
                <span>通话对您免费，请放心接听</span>`,
          dangerouslyUseHTMLString: true,
          placeholder: "请输入电话号码",
          input: true,
          inputPattern: /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/,
          inputErrorMessage: "请输入正确的手机号或带区号的固定电话",
          onConfirm: (tel) => {
            console.log(tel);

            Toast({
              type: "success",
              title: "请求已提交，电话拨打中，请稍后...",
            });

            axios.post("/M/Server/GetRandKefuName").then((res) => {
              const kefuname = res.data.msg;
              const urlstr =
                "http://211.151.35.103/app?Action=Dialout&ActionID=1234567890&Account=N000000014243&PBX=1.1.2.107&Exten=" +
                tel +
                "&FromExten=" +
                kefuname;

              window.open(urlstr);
            });
          },
        });
      }
    }
    let Videoplayer: any = null;
    let VideoplayerContent: any = null;
    const videoFileid = ref(true);
    axios
      .get("/M/Home/About")
      .then((res) => {
        if (res.data.success) {
          const kefulist = [];
          const length = Math.ceil(res.data.obj.kefulist.length / 6);
          for (let i = 0; i < length; i++) {
            kefulist.push(res.data.obj.kefulist.slice(i * 6, i * 6 + 6));
          }
          data.value = { ...res.data.obj, kefulist };
          nextTick(() => {
            Videoplayer = vTCPlayer(
              "player-container-id",
              "387702293727945337"
            );
            VideoplayerContent = vTCPlayer(
              "playerContentVideo",
              "387702293727945337"
            );
          });
        } else {
          Toast({
            type: "error",
            title: res.data.msg,
          });
        }
      })
      .catch((err) => {
        console.log("err");
      });

    watch(data, () => {
      nextTick(() => {
        const swiper = new Swiper(".swiper-container", {
          initialSlide: 0,
          autoplay: true,
          loop: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          on: {
            observerUpdate: function () {
              this.slideTo(0);
            },
          },
          pagination: {
            el: ".swiper-pagination",
          },
        });
      });
    });

    onUnmounted(() => {
      Videoplayer.dispose();
      // VideoplayerContent.dispose();
      videoFileid.value = false;
    });

    const loadOption = {
      text: "加载中...",
      color: "#b9135a",
      textColor: "#b9135a",
    };
    return {
      data,
      loadOption,
      contractUs,
      videoFileid,
    };
  },
});
</script>


<style lang="scss" scoped>
.banner {
  margin-bottom: 0.533rem;
}
.banner .swiper-content {
  width: 100%;
  height: 4rem;
  display: block;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

.banner .swiper-content img {
  /*width: 100%;*/
  height: 4rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

.banner .swiper-container {
  --swiper-theme-color: #fff;
  /* 设置Swiper风格 */
  --swiper-navigation-color: #fff;
  /* 单独设置按钮颜色 */
  --swiper-navigation-size: 1.067rem;
  /* 设置按钮大小 */
  --swiper-pagination-color: #b9135a;
  /* 两种都可以 */
}

.banner .my-bullet {
  display: inline-block;
  width: 0.347rem;
  height: 0.067rem;
  background-color: #ffffff;
  margin: 0 0.067rem;
  border-radius: 0;
  opacity: 1;
}

.banner .my-bullet-active {
  background-color: #b9135a;
}

.free-tel-model {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: none;
}

.free-tel-wapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  line-height: 1.5;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 0.267rem;
  z-index: 10001;
  display: none;
}
.free-tel-title {
  padding: 0.533rem 0.533rem 0.267rem;
  margin-bottom: -0.667rem;
  text-align: center;
  font-size: 0.467rem;
  font-weight: 400;
  color: #333;
}

#callMeTel {
  margin-left: 0.533rem;
  width: 87%;
  height: 1.067rem;
  line-height: 1.067rem;
  border: 0.027rem solid #c10728;
  padding-left: 0.267rem;
  outline: none;
}

.free-tel-btn {
  border-top: 0.027rem solid #eee;
  font-size: 0;
  margin-top: 0.533rem;
}
.free-tel-btn span {
  display: inline-block;
  height: 1.173rem;
  line-height: 1.173rem;
  color: #007aff;
  font-size: 0.453rem;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
  width: 50%;
  text-align: center;
  zoom: 1;
  box-sizing: border-box;
}

.free-tel-btn .free-tel-cancel {
  color: #999;
}
.free-tel-confirm {
  border-left: 0.027rem solid #eee;
}

.free-tel-tip {
  padding: 0.533rem 0.533rem 0.267rem;
  font-size: 0.28rem;
}

main {
  padding-bottom: 1.333rem;
}

.main-title {
  font-size: 0.46rem;
  line-height: 0.46rem;
  color: #444444;
  font-weight: bold;
  width: 9.467rem;
  margin: 0 auto;
  border-bottom: 0.027rem solid #eee;
  padding-bottom: 0.1rem;
}

.main-title-border{
  border-left:3.5px solid #b9135a;
  padding-left: 8px;
}


.main-title span {
  text-transform: uppercase;
  font-size: 10px;
  color: #666;
  font-weight: normal;
  position: relative;
  top: -5px;
}

.position-info {
  font-size: 0.4rem;
  color: #666;
  line-height: 0.64rem;
  padding: 0.4rem 0.267rem;
  width: 9.467rem;
  box-sizing: border-box;
  margin: 0 auto;
  padding-left: 0;
}
.position-info p {
  margin-left: 10px;
}
.contract-wapper {
  margin-top: 0.533rem;
  padding-left: 0.267rem;

}
.contract-wapper a {
  display: inline-block;
  width: 3.733rem;
  height: 1.067rem;
  line-height: 1.067rem;
  text-align: left;
  font-size: 0.427rem;
  border: 0.027rem solid #e5e5e5;
  border-radius: 0.133rem;
  padding-left: 0.333rem;
  box-sizing: border-box;
  color:white;
  background-color: #b9135a;
}
.contract-wapper .inline {
  margin-right: 0.8rem;
}

.contract-wapper img {
  width: 0.48rem;
  height: 0.48rem;
  vertical-align: middle;
  margin-right: 0.3rem;
}

.block {
  border-top: 0.16rem solid #eee;
  padding: 0 0.267rem;
  padding-top: 0.4rem;
  padding-bottom: 0.667rem;
}

.block-title {
  font-size: 0.46rem;
  line-height: 0.46rem;
  color: #444444;
  font-weight: bold;
  padding-bottom: 10px;
}

.block-title p {
  font-size: 10px;
  color: #666;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
  position: relative;
  top: 2px;
}

.border-bottom-1px {
  border-bottom: 0.027rem solid #eee;
}

.custom-service {
  width: 9.467rem;
  margin: 0 auto;
  padding: 0.533rem 0;
}

.custom-service-ul {
  padding-bottom: 2.133rem;
  overflow: hidden;
}
.custom-service-ul li {
  float: left;
  width: 49%;
  padding-left: 1.52rem;
  margin: 0;
  margin-bottom: 5px;
  box-sizing: border-box;
  position: relative;
  font-size: 0.293rem;
  line-height: 0.55rem;
  color: #666;
}

.custom-service-ul li img {
  width: 1.253rem;
  height: 1.253rem;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-service-ul li span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.account-info li {
  border-bottom: 0.027rem solid #eee;
  padding: 0.533rem 0;
}

.account-info p {
  font-size: 0.4rem;
  line-height: 0.587rem;
  color: #444444;
  margin: 0 auto;
  padding-left: 0.52rem;
}

.create-img {
  width: 100%;
  display: block;
}

.tip {
  width: 100%;
  height: 6.933rem;
  background-color: #b9135a;
  text-align: center;
  font-size: 0.533rem;
  color: #fff;
  padding-top: 2.667rem;
  box-sizing: border-box;
  font-weight: bold;
}

.tip p {
  font-size: 0.373rem;
  color: #fff;
  margin-top: 0.4rem;
  font-weight: normal;
}

.tip-container p {
  font-size: 0.4rem;
  color: #444444;
  line-height: 0.587rem;
  //margin-top: 0.267rem;
  //text-indent: 2em;
}

.tip-container a {
  color: #b9135a;
}

.development-history {
  border-top: 0.16rem solid #eee;

  padding: 0 0.267rem;
  padding-top: 0.667rem;
  padding-bottom: 1.333rem;
}
.dev-title {
  text-align: center;
  font-size: 0.46rem;
  font-weight: bold;
  color: #444444;
}

.dev-desc {
  font-size: 0.4rem;
  color: #444444;
  margin-top: 0.4rem;
  font-weight: bold;
  text-align: center;
}

.dev-list {
  font-weight: normal;
  font-size: 0.427rem;
  color: #444444;
  border-bottom: 0.027rem solid #eee;
}

.dev-list p {
  margin: 1em 0;
}

.video-wapper {
  width: 9.467rem;
  height: 5.333rem;
  margin: 0.4rem auto;
}

.hot-title {
  width: 100%;
  height: 45px;
  border-bottom: 1px solid #f4f4f4;
  font-size: 15px;
  padding-left: 21px;
  box-sizing: border-box;
  position: relative;
  line-height: 48px;
  margin-bottom: 12px;
  color: #333;
  &::after {
    position: absolute;
    content: "";
    left: 10.5px;
    top: 50%;
    width: 2.5px;
    height: 13px;
    transform: translateY(-40%);
    background-color: #b9135a;
  }
}
</style>
